import React from "react";
import Layout from "../../components/layout";
import "./datadog.scss";

const DataDog = () => (
  <Layout showHeader={false} showFooter={false}>
    <h2>Hi there Datadog design team!</h2>
    <p>
      I am SUPER excited about the prospect of joining Datadog, especially after
      I got to meet so many of you! Here is a bit more of my work for you to all
      see.
    </p>
    <p>
      I give some context below, but my intent here is not to show you how I
      solved these problems. I intend to share the raw assets of my work so that
      you may inspect them.
    </p>
    <h3>A summary of what is below:</h3>
    <ul>
      <li>
        A complete design system with typographic rules, colors, states and
        components
      </li>
      <li>
        The usage of the above design system to produce UI's for three different
        experiences all part of the same product suite
      </li>
      <li>A redesign of an interactive media product</li>
      <li>The setup of a design system in code</li>
      <li>My favorite UI invention :)</li>
    </ul>
    <h2>HSS Design System</h2>
    <p>
      This design system was put together for the Hospital for Special Surgery.
      Their Chief Venture Officer is investing in a new experience for patients,
      doctors and hospital staff to interact with each other for their surgical
      needs.
    </p>
    <p>
      The patients using this system spanned the spectrum of age and technical
      ability and needed to be usable across that entire spectrum.
    </p>
    <p>
      The data that needed to be collected from the patients needed to be
      presented to doctors concisely, optimizing for speed of access and not
      necessarily usability.
    </p>
    <p>
      The hospital staff needed to manage and create many types of surveys with
      particular language that had been validated by medical researchers.
    </p>
    <ul>
      <li>
        <a href="/datadog/hss-patient-doctor-tool.pdf">PDF</a>
      </li>
      <li>
        <a href="/datadog/hss-master.sketch">Sketch file</a>
      </li>
    </ul>
    <h2>UI/UX Redesign</h2>
    <p>
      I have been working with a client that has an odd product called{" "}
      <a href="http://voicethread.com">VoiceThread</a>. The company has been
      around for 9 years, and has revenue in the millions...and yet the product
      violates almost everything we know to be good design.
    </p>
    <p>
      The company has identified it's lack of good design as a barrier for
      growth and they hired me to help solve it.
    </p>
    <p>
      Solving their design problems has a few dimensions to it that must be
      considered:
    </p>
    <ul>
      <li>
        They only have two release cycles per year due to contractual agreements
        with their customers
      </li>
      <li>
        The organization has a culture of not respecting good design practices
      </li>
      <li>
        Front end developers at this company are not communicative, and if
        lacking information will make design choices on their own
      </li>
      <li>
        A culture of reasoning and concluding about customer behavior without
        research
      </li>
    </ul>
    <p>
      I give the above context because the design I present to you below is the
      result of having worked through all of the challenges above over many
      months.
    </p>
    <p>
      The company is incredibly nervous about touching the design of their core
      product, and the fact that I have been able to execute a complete redesign
      is a success.
    </p>
    <ul>
      <li>
        <a href="https://www.figma.com/file/Ki8IwxgTXxQvIEArkjwy0g/user-flows?node-id=0%3A1">
          User flows - (figma - sign up for a free account to inspect file more
          closely)
        </a>
      </li>
      <li>
        <a href="https://www.figma.com/file/o9adTDKUlCKAr3yDtY4ylr/ui-create?node-id=40%3A3337">
          Design file - (figma - sign up for a free account to inspect file more
          closely)
        </a>
      </li>
      <li>
        <a href="https://www.figma.com/proto/o9adTDKUlCKAr3yDtY4ylr/ui-create?node-id=26%3A6871&viewport=163%2C411%2C0.163268&scaling=min-zoom">
          Organize slides - (prototype'ish - entire artboard is one hotspot for
          each screen to demonstrate a flow)
        </a>
      </li>
      <li>
        <a href="https://www.figma.com/proto/o9adTDKUlCKAr3yDtY4ylr/ui-create?node-id=37%3A2925&viewport=160%2C488%2C0.125&scaling=min-zoom">
          Drag and drop files to upload - (prototype'ish - entire artboard is
          one hotspot for each screen to demonstrate a flow)
        </a>
      </li>
    </ul>
    <h2>Design system in code</h2>
    <p>
      The front end development challenge I described in the section previous to
      this one was substantial due to some communication issues that are not
      easily solved. So to avoid poor implementations of design I created all of
      the components designed by me in HTML & CSS.
    </p>
    <p>
      I then worked with the developers on a build process that would pull in
      all of this markup and styling code so that the product would always have
      the right styling.
    </p>
    <p>
      As a result, no pixel pushing was necessary from design --> dev handoff
      and handoffs didn't have to be design files. They could be text documents
      illustrating the names of components that were to be used.
    </p>
    <ul>
      <li>
        <a href="https://voicethread.github.io/design/">
          Link to live design system
        </a>
      </li>
    </ul>
    <h2>My favorite UI</h2>
    <p>
      A few years ago I got to work on a blue ocean project to invent a music
      listening experience.
    </p>
    <p>
      My favorite thing about this project is the playback controls. At the time
      most mobile music experiences had a collection of UI elements on the
      "currently playing" screens that looked like this:
    </p>
    <ul>
      <li>Cover art</li>
      <li>Next song</li>
      <li>Previous song</li>
      <li>Play / Pause</li>
      <li>Timeline and scrubber</li>
      <li>Song length</li>
      <li>Current time</li>
      <li>Artist name</li>
      <li>Song name</li>
      <li>Volume</li>
    </ul>
    <p>
      This was 2014 and swiping cards were all the rage because of Tinder, and I
      thought it would be a fun way to interact with a music experience that was
      focused exclusively on the DISCOVERY of music.
    </p>
    <p>
      Additionally, if this app was to be about discovery then you may not want
      to listen to the entire song...but, you might be inclined to skip through
      it quickly.
    </p>
    <p>
      <a href="https://nycode.co/img/projects/reverb-discover/discover.png">
        LINK: The final UI looked like this
      </a>
    </p>
    <p>
      Four of the UI elements were combined into one. The play/pause button was
      also draggable, allowing for a huge tap target to skip through the song.
      Additionally, the total number of UI elements was reduced as a result.
    </p>
    <p>
      Secretly I was hoping all music products would adopt this UI invention,
      but maybe it's not as awesome as I think it is :)
    </p>
    <p>
      This project was also the first time I had used Sketch & InVision instead
      of Photoshop for UI design. I have provided the sketch files below, but
      keep in mind this was 5 years ago and I was learning how to use Sketch &
      InVision.
    </p>
    <ul>
      <li>
        <a href="/datadog/discover_IOS.sketch">Sketch file</a>
      </li>
      <li>
        <a href="/datadog/discover_prototype.sketch">
          Sketch file with flat images for prototype
        </a>
      </li>
      <li>
        <a href="https://invis.io/MCPE9UO2AX3">InVision prototype</a>
      </li>
    </ul>
    <h3>One final note...</h3>
    <p>
      <a href="https://christopherandersson.com">
        This website that you are looking at
      </a>{" "}
      is 100% custom built and designed by me. It's{" "}
      <a href="https://reacthjs.org">React</a> and{" "}
      <a href="https://gatsbyjs.org">Gatsby</a>, and hosted on S3 with
      Cloudfront.
    </p>
    <p>
      This site is ridiculous fast with a 99 rating out of 100 (
      <a href="/datadog/christopherandersson.com_2019-01-22_13-11-17.html">
        see the actual report here
      </a>
      ) for performance by Google, and if I ever ended up at the top of
      Reddit...it wouldn't flinch at the traffic that it got.
    </p>
    <p>
      I was really striving for simplicity and good typography (which is an area
      I am looking to improve upon). If you use your browser dev tools you can
      see how the 24px vertical rhythm works which I think is pretty cool.
    </p>
    <h2>Hope to hear from you soon!</h2>
  </Layout>
);

export default DataDog;
